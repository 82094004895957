var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MarketingActivities" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "ul",
            { staticClass: "prizeType" },
            [
              _c("li", { staticClass: "type-title" }, [_vm._v("分类：")]),
              _vm._l(_vm.prizeTypeList, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "type-item",
                    class: { active: index == _vm.selected },
                    on: {
                      click: function($event) {
                        return _vm.prizeTypeShow(item, index)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              })
            ],
            2
          )
        ]),
        _c(
          "div",
          { staticClass: "productList" },
          [
            _vm.dataList.length
              ? _vm._l(_vm.dataList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "product-item",
                      class: { active: _vm.current == index },
                      on: {
                        mouseover: function($event) {
                          _vm.current = index
                        },
                        mouseout: function($event) {
                          _vm.current = null
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/img/marketing/title.png"),
                          alt: "",
                          width: "100%"
                        }
                      }),
                      _c("div", { staticClass: "title-name" }, [
                        _c("i", { staticClass: "el-icon-close" }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              item.page.activityBase
                                ? item.page.activityBase.activityName
                                : ""
                            )
                          )
                        ]),
                        _c("i", { staticClass: "el-icon-more" })
                      ]),
                      _c("div", {
                        staticClass: "activeBackground",
                        style: {
                          backgroundImage: item.page.activityBase
                            ? "url(" + item.page.activityBase.cover + ")"
                            : "",
                          backgroundSize: "100% 100%"
                        }
                      }),
                      _c("p", { staticClass: "name" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.page.activityBase
                                ? item.page.activityBase.name
                                : ""
                            ) +
                            " "
                        )
                      ]),
                      _c("div", { staticClass: "type" }, [
                        item.isFree
                          ? _c("span", { staticClass: "price" }, [
                              _vm._v("免费")
                            ])
                          : item.templeStatus == 1
                          ? _c("span", { staticClass: "price" }, [
                              _vm._v("限免")
                            ])
                          : _c("span", { staticClass: "price" }, [
                              _vm._v(_vm._s("￥" + item.sellingPrice + "元"))
                            ]),
                        _c("span", { staticClass: "money" }, [
                          _vm._v(
                            _vm._s(
                              item.originalPrice
                                ? "￥" + item.originalPrice + "元"
                                : ""
                            )
                          )
                        ]),
                        _c("span", { staticClass: "type-text" }, [
                          _c("i", [
                            _vm._v(
                              _vm._s(_vm.findType(item.page.activityBase.type))
                            )
                          ])
                        ])
                      ]),
                      _c("div", [
                        item.isCollect
                          ? _c("i", {
                              staticClass: "collect",
                              class: {
                                "el-icon-star-on": _vm.current == index
                              },
                              on: {
                                click: function($event) {
                                  return _vm.collectActive(item)
                                }
                              }
                            })
                          : _c("i", {
                              staticClass: "collect",
                              class: {
                                "el-icon-star-off": _vm.current == index
                              },
                              on: {
                                click: function($event) {
                                  return _vm.collectActive(item)
                                }
                              }
                            }),
                        _c(
                          "div",
                          { staticClass: "activeSet show" },
                          [
                            item.demoUrl && _vm.isShow
                              ? _c("active-qrcode", {
                                  staticClass: "code",
                                  attrs: {
                                    url: item.demoUrl + "?temple=" + item.id,
                                    codeSize: "100"
                                  }
                                })
                              : _c("div", { staticClass: "code" }),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function($event) {
                                    return _vm.createAct(item.id)
                                  }
                                }
                              },
                              [_vm._v("创建活动")]
                            )
                          ],
                          1
                        )
                      ])
                    ]
                  )
                })
              : [
                  _c(
                    "div",
                    { staticStyle: { height: "500px" } },
                    [_c("listNull", { attrs: { text: "此处还没有活动~" } })],
                    1
                  )
                ]
          ],
          2
        ),
        _c("el-pagination", {
          staticClass: "page",
          attrs: {
            "current-page": _vm.page,
            "page-sizes": [10, 20, 50, 100],
            "page-size": _vm.limit,
            total: _vm.totalPage,
            layout: "total, sizes, prev, pager, next, jumper"
          },
          on: {
            "size-change": _vm.sizeChangeHandle,
            "current-change": _vm.currentChangeHandle
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }