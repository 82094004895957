<template>
  <div class="MarketingActivities">
    <div class="content">
      <div class="row">
        <ul class="prizeType">
          <li class="type-title">分类：</li>
          <li
            v-for="(item, index) in prizeTypeList"
            :key="index"
            class="type-item"
            :class="{ active: index == selected }"
            @click="prizeTypeShow(item, index)"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div class="productList">
        <template v-if="dataList.length">
          <div
            class="product-item"
            v-for="(item, index) in dataList"
            :key="index"
            @mouseover="current = index"
            @mouseout="current = null"
            :class="{ active: current == index }"
          >
            <img src="@/assets/img/marketing/title.png" alt="" width="100%" />
            <div class="title-name">
              <i class="el-icon-close"></i>
              <span>{{
                item.page.activityBase
                  ? item.page.activityBase.activityName
                  : ""
              }}</span>
              <i class="el-icon-more"></i>
            </div>
            <div
              :style="{
                backgroundImage: item.page.activityBase
                  ? 'url(' + item.page.activityBase.cover + ')'
                  : '',
                backgroundSize: '100% 100%',
              }"
              class="activeBackground"
            ></div>
            <!-- <p class="name"><span class="freeActive">免费</span>{{ item.page.activityBase ? item.page.activityBase.name : '' }}</p> -->
            <p class="name">
              {{ item.page.activityBase ? item.page.activityBase.name : "" }}
            </p>
            <div class="type">
              <span class="price" v-if="item.isFree">免费</span>
              <span class="price" v-else-if="item.templeStatus == 1">限免</span>
              <span class="price" v-else>{{
                "￥" + item.sellingPrice + "元"
              }}</span>
              <span class="money">{{
                item.originalPrice ? "￥" + item.originalPrice + "元" : ""
              }}</span>
              <span class="type-text">
                <i>{{ findType(item.page.activityBase.type) }}</i>
<!--                <i>-->
<!--                  {{-->
<!--                    item.page.activityBase-->
<!--                      ? typeList[item.page.activityBase.type]-->
<!--                      : ""-->
<!--                  }}-->
<!--                </i>-->
              </span>
            </div>
            <div>
              <i
                :class="{ 'el-icon-star-on': current == index }"
                class="collect"
                @click="collectActive(item)"
                v-if="item.isCollect"
              ></i>
              <i
                :class="{ 'el-icon-star-off': current == index }"
                class="collect"
                @click="collectActive(item)"
                v-else
              ></i>
              <div class="activeSet show">
                <active-qrcode
                  :url="`${item.demoUrl}?temple=${item.id}`"
                  v-if="item.demoUrl && isShow"
                  class="code"
                  codeSize="100"
                ></active-qrcode>
                <div v-else class="code"></div>
                <el-button type="primary" @click="createAct(item.id)"
                  >创建活动</el-button
                >
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div style="height: 500px">
            <listNull text="此处还没有活动~" />
          </div>
        </template>
      </div>

      <el-pagination
        class="page"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Classification from '@/components/Classification.vue'
import { getLocal } from '@/lib/local'
import { getList, addCollect, delCollect, isHLAcount, getLoginList } from '@/api/market'
import ActiveQrcode from '@/components/ActiveQrcode.vue'
import listNull from '@/components/listNull.vue'
import { getRoleIds } from '@/lib/util'

export default {
  data () {
    return {
      page: 1,
      limit: 10,
      totalPage: 0,
      current: null,
      selected: 0,
      dataList: '',
      isShow: false,
      isLogin: false, // 是否登录
      typeList: ['开宝箱', '九宫格', '砸金蛋', '大转盘', '小兔兔奔月记', '红旗护卫', '画金蛋', '幸运大翻转', '欢乐抽奖机', '阅读有礼', '刮刮乐', '投壶', '潮前看',
        '红包雨', '海报分享', '集卡', '大富翁', '拼拼乐', '竞猜', '答题', '养兔子', '回家', '红包树', '现场签到', '滚蛋吧负能量', '最美集赞', '扭蛋',
        '南北大比拼', '数字谜盘', '游泳竞赛', '接力跑竞赛', '欢乐娃娃机', '点球大战', '票票抽奖', '赛龙舟'],
      name: this.$route.params.name || '',
      prizeTypeList: [
        {
          value: null,
          label: '全部'
        },
        {
          value: 0,
          label: '开宝箱'
        },
        {
          value: 1,
          label: '九宫格'
        },
        {
          value: 2,
          label: '砸金蛋'
        },
        {
          value: 3,
          label: '大转盘'
        },
        {
          value: 4,
          label: '小兔兔奔月记'
        },
        {
          value: 5,
          label: '红旗护卫'
        },
        {
          value: 6,
          label: '画金蛋'
        },
        {
          value: 7,
          label: '幸运大翻转'
        },
        {
          value: 8,
          label: '欢乐抽奖机'
        },
        {
          value: 9,
          label: '阅读有礼'
        },
        // { // '二维码九宫格'只限定'红辽用户'显示!!!!!由js动态添加
        //   value: 10,
        //   label: '二维码九宫格'
        // },
        {
          value: 11,
          label: '刮刮乐'
        },
        {
          value: 12,
          label: '投壶'
        },
        {
          value: 13,
          label: '潮前看'
        },
        {
          value: 14,
          label: '红包雨'
        },
        {
          value: 15,
          label: '活动分享'
        },
        {
          value: 16,
          label: '集卡'
        },
        {
          value: 17,
          label: '大富翁'
        },
        {
          value: 18,
          label: '拼拼乐'
        },
        {
          value: 19,
          label: '竞猜'
        },
        {
          value: 20,
          label: '答题'
        },
        {
          value: 21,
          label: '养宠物'
        },
        {
          value: 22,
          label: '回家'
        },
        {
          value: 23,
          label: '红包树'
        },
        {
          value: 24,
          label: '现场签到'
        }, {
          value: 25,
          label: '滚蛋吧负能量'
        }, {
          value: 26,
          label: '最美集赞'
        }, {
          value: 27,
          label: '扭蛋'
        }, {
          value: 28,
          label: '南北大比拼'
        },
        {
          value: 29,
          label: '数字谜盘'
        },
        {
          value: 30,
          label: '游泳竞赛'
        },
        {
          value: 31,
          label: '接力跑竞赛'
        },
        {
          value: 32,
          label: '欢乐娃娃机'
        },
        {
          value: 33,
          label: '点球大战'
        },
        {
          value: 34,
          label: '票票抽奖'
        },
        {
          value: 37,
          label: '赛龙舟'
        }
      ]
    }
  },
  components: {
    Classification,
    ActiveQrcode,
    listNull
  },
  created () {
    this.userType()
    this.getActiveData()
    this.$bus.$on('searchActive', (name) => {
      this.name = name
      this.selected = 0
      this.getActiveData()
    })
  },
  beforeDestroy () {
    this.$bus.$off('searchActive')
  },
  methods: {
    findType (n) {
      const arr = this.prizeTypeList.filter((item) => {
        return item.value == n
      })
      if (arr.length) {
        return arr[0].label
      } else {
        return '未知活动模板'
      }
    },
    // 查询用户信息
    accountInfo () {
      isHLAcount().then(res => {
        if (res.code === 200) {
          if (res.data && res.data.flag) {
            this.typeList.push('二维码九宫格')
            this.prizeTypeList.push({
              value: 10,
              label: '二维码九宫格'
            })
          }
        }
      })
    },
    userType () {
      this.isLogin = !!getLocal()
      if (this.isLogin) {
        this.accountInfo()
      }
    },
    // 获取活动列表
    async getActiveData (type = null) {
      this.isShow = false
      const res = await (this.isLogin ? getLoginList : getList)({ pageSize: this.limit, pageNO: this.page, type, name: this.name, userId: getRoleIds().userId })
      if (res.code == 200) {
        this.dataList = res.data.list
        this.totalPage = res.data.total
        this.dataList.forEach(item => {
          item.page = JSON.parse(item.page)
        })
        this.isShow = true
      }
    },
    // 点击创建
    createAct (id) {
      sessionStorage.removeItem('data')
      const { href } = this.$router.resolve({ path: '/MarketingActivityEdit', query: { id: id } })
      window.open(href, '_blank')
    },
    prizeTypeShow (item, index) {
      this.selected = index
      this.name = ''
      this.page = 1
      this.getActiveData(item.value)
    },
    // 每页数
    sizeChangeHandle (val) {
      this.limit = val
      this.page = 1
      this.getActiveData()
    },
    // 当前页
    currentChangeHandle (val) {
      this.page = val
      this.getActiveData()
    },
    // 收藏/取消收藏
    async collectActive (data) {
      let res
      if (data.isCollect) {
        res = await delCollect({ templeId: data.id })
      } else {
        res = await addCollect(JSON.stringify({ templeId: data.id }))
      }
      if (res.code == 200) {
        data.isCollect ? this.$message.success('取消收藏成功') : this.$message.success('收藏成功')
        this.getActiveData(this.prizeTypeList[this.selected].value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.MarketingActivities {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .content {
    width: 1210px;
    .row {
      margin-top: 30px;
    }
    .prizeType {
      display: flex;
      flex-wrap: wrap;
      .type-title {
        font-size: 14px;
        text-align: center;
        padding: 5px;
      }
      .type-item {
        cursor: pointer;
        margin-right: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        background: #F3F4F8;
        collapse: #313131;
        text-align: center;
        padding: 5px 20px;
        border-radius: 15px;
      }
      .active {
        background-color: #EBF1FE;
        color: #3C74F1;
      }
    }
    .page {
      margin: 30px 0;
      text-align: right;
    }
  }
}

.productList {
  margin-top: 20px;
  &:after,
  &:before {
    content: "";
    display: block;
    clear: both;
  }

  .active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.3;
    z-index: 10;
  }
  .active .collect {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 28px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    font-size: 18px;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    z-index: 20;
    color: #00aeff;
  }
  .active .show {
    display: block;
  }
  .activeSet {
    position: absolute;
    top: 30%;
    left: 30%;
    display: none;
    z-index: 999;
    .code {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
    }
  }
}
.product-item {
  position: relative;
  float: left;
  width: 215px;
  height: 485px;
  margin: 0px 20px 20px 0;
  border: 1px solid #ccc;
  transition: all 0.2s linear;
  border-radius: 5px;
  &:nth-of-type(5n){
    margin-right: 0;
  }
  &:hover {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
    transform: translate3d(0, -4px, 0);
  }
  .title-name {
    position: absolute;
    top: 3%;
    left: 0;
    width: 100%;
    height: 30px;
    display: flex;
    font-size: 12px;
    align-items: center;
    background-color: #f2f2f2;
    span {
      flex: 1;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .activeBackground {
    height: 382px;
    width: 100%;
    margin-top: 20px;
  }
  .name {
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #33383e;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 10px 10px 0;
  }
  .type {
    display: flex;
    margin: 0 10px 0;
    color: #c8c8c8;
    font-size: 12px;
    height: 20px;
    align-items: center;
    .price {
      margin-right: 5px;
      color: #ee0c19;
    }
    .money {
      text-decoration: line-through;
    }
    .type-text {
      flex: 1;
      text-align: right;
      color: #888;
      i {
        font-style: normal;
        border-radius: 2px;
        padding: 2px;
        background: #f5f7fa;
      }
    }
  }
}
</style>
