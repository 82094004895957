<template>
  <div class="Classification">
    <span class="title">{{ title }}:</span>
    <ul class="class-list">
      <li
        class="class-item"
        v-for="(item, index) in dataList"
        :key="index"
        @click="classify(index)"
        :class="{ active: current == index }"
      >
        {{ item.classificationName }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    dataList: {
      type: Array,
      default: []
    },
    //    current: {
    //      type: Array,
    //      default: []
    //    }
  },
  data () {
    return {
      current: 0
    }
  },
  methods: {
    classify (index) {
      let arr = []
      console.log(index)
      if (index != 0) {
        // console.log(this.dataList)
        for (const item of this.dataList[index].childrenList) {
          // console.log(item.id)
          // for()
          if (item.id) {
            arr.push(item.id)
          }
        }
      }
      console.log(arr)
      this.current = index
      this.$emit('classify', arr)
    }
  }
}
</script>

<style lang="scss" scoped>
.Classification {
  display: flex;
  // align-items: center;
  .title {
    width: 50px;
    display: inline-block;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 30px;
  }
  .class-list {
    list-style-type: none;
    float: left;
    padding: 0;
    margin: 0;
    .class-item {
      float: left;
      margin-right: 30px;
      margin-bottom: 5px;
      // background-color: #f7f8ff;
      padding: 5px;
      border-radius: 2px;
      height: 28px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #444950;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
    }
    .active {
      color: #3c74f1ff;
      background-color: #ebf1feff;
    }
  }
}
</style>
