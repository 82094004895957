var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Classification" }, [
    _c("span", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title) + ":")]),
    _c(
      "ul",
      { staticClass: "class-list" },
      _vm._l(_vm.dataList, function(item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "class-item",
            class: { active: _vm.current == index },
            on: {
              click: function($event) {
                return _vm.classify(index)
              }
            }
          },
          [_vm._v(" " + _vm._s(item.classificationName) + " ")]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }